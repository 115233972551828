import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseWebService } from 'app/core/services/base-web.service';
import { FeatureFlagEnum } from '../feature-flag.enum';
import { map, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { setFeatureFlag } from '../store/feature-flag.action';
import { FeatureFlagInterface } from '../store/feature-flag.reducer';

@Injectable()
export class FeatureFlagService extends BaseWebService {
  private readonly flags$: Observable<FeatureFlagInterface[]>;

  constructor(
    private http: HttpClient,
    private store: Store<{ flags: FeatureFlagInterface[] }>
  ) {
    super('identity/FeatureFlag', http);
    this.flags$ = this.store.select('flags');
  }

  getAllFlags(): Observable<
    {
      flag: FeatureFlagEnum;
      state: boolean;
    }[]
  > {
    return this.flags$.pipe(
      map((e) => {
        let flag = e.map((x) => {
          return {
            flag: FeatureFlagEnum[x.flag as keyof typeof FeatureFlagEnum],
            state: x.state,
          };
        });
        return flag;
      })
    );
  }

  isEnabled(flag: FeatureFlagEnum) {
    return this.flags$.pipe(
      map((e) => {
        let featureFlag = e.find((x) => x.flag == FeatureFlagEnum[flag]);
        if (featureFlag == undefined) return false;
        return featureFlag.state;
      })
    );
  }

  canActivate() {
    return new Promise<boolean>((resolve) => {
      this.getFlags().then(() => {
        resolve(true);
      });
    });
  }

  getFlags() {
    return new Promise((resolve, reject) => {
      this.getMethod<FeatureFlagInterface[]>().subscribe((flagsToSet) => {
        this.store.dispatch(setFeatureFlag({ flags: flagsToSet }));
        resolve(true);
      });
    });
  }
}
