import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

const TOKEN_NAME: string = "token";
const ID_NAME: string = "id";
const USERNAME_NAME: string = "username";
const TIMEZONE_NAME: string = "timezone";

@Injectable()
export class AuthService {


  constructor(public jwtHelper: JwtHelperService) { }
  saveDataInBrowser(data: any) {
    localStorage.setItem(TOKEN_NAME, data.token);
    localStorage.setItem(ID_NAME, data.id);
    localStorage.setItem(USERNAME_NAME, data.userName);
    localStorage.setItem(TIMEZONE_NAME, data.timezone);
  }
  removeData() {
    localStorage.removeItem(TOKEN_NAME);
    localStorage.removeItem(ID_NAME);
    localStorage.removeItem(USERNAME_NAME);
    localStorage.removeItem(TIMEZONE_NAME);
  }
  getToken(): string | null {
    return getToken();
  }
  isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.getToken() ?? "");
  }
  getUsername(): string | null {
    return localStorage.getItem(USERNAME_NAME);
  }
  getUserId(): string | null {
    return getUserId();
  }
}

export function getToken() {
  return localStorage.getItem(TOKEN_NAME);
}
export function getUserId() {
  return localStorage.getItem(ID_NAME);
}
export function getTimezone(){
  return localStorage.getItem(TIMEZONE_NAME);
}
