import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PagedList } from '../models/paged-list.model';

export class BaseWebService {
  protected readonly url: string;
  protected httpHeaders: HttpHeaders;

  constructor(controller: string, private httpClient: HttpClient) {
    this.url = `api/${controller}/`;

    this.httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
  }

  protected getMethod<T>(
    method: string = '',
    params?: HttpParams
  ): Observable<T> {
    return this.httpClient.get<T>(this.url + method, {
      params: params,
      headers: this.httpHeaders,
    });
  }

  protected getPagedListMethod<T, K>(
    method: string = '',
    pagedListParams: K
  ): Observable<PagedList<T>> {
    let params = new HttpParams();

    for (const [key, value] of Object.entries(<any>pagedListParams)) {
      params = params.set(key, <string>value);
    }

    return this.getMethod<PagedList<T>>(method, params);
  }

  protected putMethod<T>(method: string = '', obj: any) {
    return this.httpClient.put<T>(this.url + method, obj, {
      headers: this.httpHeaders,
    });
  }

  protected postMethod<T>(method: string = '', body: any) {
    return this.httpClient.post<T>(this.url + method, body, {
      headers: this.httpHeaders,
    });
  }

  protected postMethodNoHeaders<T>(method: string = '', body: any) {
    return this.httpClient.post<T>(this.url + method, body);
  }

  protected postFormMethod<T>(method: string = '', body: any) {
    return this.httpClient.post<T>(this.url + method, body);
  }

  protected postMethodFile(method: string = '', body: any) {
    return this.httpClient.post(this.url + method, body, {
      headers: this.httpHeaders,
      responseType: 'blob',
    });
  }

  protected getMethodFile(method: string = '', body: any) {
    return this.httpClient.get<Blob>(this.url + method, {
      observe: 'response',
      responseType: 'blob' as 'json',
    });
  }

  protected deleteMethod(method: string = '') {
    return this.httpClient.delete(this.url + method, {
      headers: this.httpHeaders,
    });
  }
}
