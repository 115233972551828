import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseWebService } from 'app/core/services/base-web.service';
import { AccountsUser } from '@app/modules/accounts/models/accounts-user.model';
import { PagedListParams } from '@app/core/models/paged-list-params.model';
import { UserUpdate } from '@app/modules/accounts/models/user-update.model';
import { UserCreate } from '@app/modules/accounts/models/user-create.model';
import { MailRegistrationRequest } from '../models/mail-registration-request';
import { Observable } from 'rxjs';
import { UserImportResult } from '@app/modules/accounts/models/user-import-result';
import { UserImportInfo } from '@app/modules/accounts/models/user-import-info';
import { UserBaseAccounts } from '../models/user-base-accounts.model';
import { UserAccount } from '../models/user-account';
import { AccountDivision } from '../models/account-division';

@Injectable()
export class UsersService extends BaseWebService {
  constructor(private http: HttpClient) {
    super('identity/users', http);
  }

  getUsersPagedList(usersListParams: PagedListParams) {
    return this.getPagedListMethod<AccountsUser, PagedListParams>(
      'AccountUsers',
      usersListParams
    );
  }

  getUser(userId: string) {
    return this.getMethod<UserBaseAccounts>(userId);
  }

  getMyAccountInfo() {
    return this.getMethod<UserBaseAccounts>('MyAccountInfo');
  }

  updateProfile(userDto: UserUpdate) {
    return this.postMethod<UserUpdate>('UpdateProfile', userDto);
  }

  createUser(userDto: UserCreate) {
    return this.postMethod<UserCreate>('Create', userDto);
  }

  updateUser(userDto: UserCreate) {
    return this.postMethod<UserCreate>('Update', userDto);
  }

  switchAccount(idAccount: number) {
    return this.postMethod<UserCreate>(`SwitchAccount/${idAccount}`, null);
  }
  sendMailRegistration(request : MailRegistrationRequest){
    return this.postMethod(`SendMailRegistration`,request);
  }

  importUsers(formData: FormData): Observable<UserImportResult> {
    return this.postMethodNoHeaders("ImportUsers", formData);
  }

  getImportInfoPagedList(listParams: PagedListParams) {
    return this.getPagedListMethod<UserImportInfo, PagedListParams>(`PagedUserImportInfos`, listParams);
  };

  addUpdateAccount(userId: string, account: UserAccount) {
    return this.postMethod<number>(`AddUpdateAccount/${userId}`, account);
  }

  deleteAccount(id: number) {
    return this.deleteMethod(`DeleteAccount/${id}`);
  }

  addUpdateDivisionProgram(accountId: number, accountDivision: AccountDivision) {
    return this.postMethod<UserAccount[]>(`AddUpdateDivisionProgram/${accountId}`, accountDivision);
  }

  deleteDivisionProgram(id: number) {
    return this.deleteMethod(`DeleteDivisionProgram/${id}`);
  }
}
