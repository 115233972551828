import { Injectable } from '@angular/core';
import { Dictionary } from '@app/core/models/dictionary.model';
import { BehaviorSubject, Subscription, skip } from 'rxjs';
import { ConfigUserService } from './config-user.service';
import { UserAccount } from '@app/modules/accounts/models/user-account';
import { ActiveUserAccount } from '@app/modules/accounts/models/active-user-account.model';
import { BarSelectorBS } from '../model/bar-selector-bs.model';
import { AuthService } from 'services/auth-service/auth.service';

@Injectable()
export class BarSelectorService {
  public static readonly divisionDefault: Dictionary = {
    id: 0,
    name: 'All Divisions',
  };
  public static readonly programDefault: Dictionary = {
    id: 0,
    name: 'All Programs',
  };
  public static readonly subProgramDefault: Dictionary = {
    id: 0,
    name: 'All SubPrograms',
  };

  private static behaviorSubject: BehaviorSubject<BarSelectorBS> =
    new BehaviorSubject({
      //division: BarSelectorService.divisionDefault,
      //program: BarSelectorService.programDefault,
      //subProgram: BarSelectorService.subProgramDefault,
      division: { id: 0, name: 'All Divisions' },
      program: { id: 0, name: 'All Programs' },
      subProgram: { id: 0, name: 'All SubPrograms' },
    });
  private static accountsbehaviorSubject: BehaviorSubject<{
    dictionary: Dictionary[];
    active: any;
  }> = new BehaviorSubject({
    dictionary: new Array<Dictionary>(),
    active: null,
  });

  private static isLoad = false;
  private nameDivisionProgram = 'divisionProgramConf';

  constructor(
    private configService: ConfigUserService,
    private authService: AuthService
  ) {
    if (!BarSelectorService.isLoad && authService.isAuthenticated()) {
      this.configService
        .getConfigUser(this.nameDivisionProgram)
        .subscribe((data) => {
          if (data != null) {
            this.nextValue(
              data.value.division,
              data.value.program,
              data.value.subProgram
            );
          }

          BarSelectorService.isLoad = true;
        });
    }
  }

  public nextValue(
    division: Dictionary,
    program: Dictionary,
    subProgram: Dictionary
  ) {
    return new Promise((resolve) => {
      BarSelectorService.behaviorSubject.next({
        division: division,
        program: program,
        subProgram: subProgram,
      });

      this.configService
        .setConfigUser(this.nameDivisionProgram, this.getDivisionProgram())
        .subscribe((x) => {
          resolve(true);
        });
    });
  }

  public subscribe(
    next: (value: BarSelectorBS) => void,
    skipCount: number = 0
  ): Subscription {
    if (skipCount > 0)
      return BarSelectorService.behaviorSubject
        .pipe(skip(skipCount))
        .subscribe(next);

    return BarSelectorService.behaviorSubject.subscribe(next);
  }

  public subscribeAccounts(
    next: (value: { dictionary: Dictionary[]; active: any }) => void
  ): Subscription {
    return BarSelectorService.accountsbehaviorSubject.subscribe(next);
  }

  public getDivisionProgram() {
    return BarSelectorService.behaviorSubject.getValue();
  }

  public setAccounts(
    accounts: UserAccount[],
    activeAccount: ActiveUserAccount | null
  ) {
    let array: Dictionary[] = [];
    accounts.forEach((x) => {
      array.push({ id: x.id as number, name: x.name });
    });

    BarSelectorService.accountsbehaviorSubject.next({
      dictionary: array,
      active: activeAccount,
    });
  }

  public getAccounts() {
    let toCopy = BarSelectorService.accountsbehaviorSubject.value;
    return JSON.parse(JSON.stringify(toCopy)) as {
      dictionary: Dictionary[];
      active: any;
    };
  }
}
