import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { setRoles } from '@app/store/actions/roles.action';
import { RoleItem } from '@app/store/models/role-item';
import { Store } from '@ngrx/store';
import { Observable, map } from 'rxjs';
import { ApiService } from 'services/api-service/api.service';

export enum Roles{
  SYSADMIN = 0,
  USERS_READ = 1,
  USERS_WRITE = 2,
  ORDERS_READ = 10,
  ORDERS_WRITE = 11,
  SUSTAINABILITY_READ = 12,
  DOCUMENTS_READ = 13,
  DOCUMENTS_WRITE = 14,
  WEBHOOK = 15,
  REPORTS_READ = 16,
  DASHBOARD_READS = 17,
  REPORTS_WRITE = 18
}
export interface RoleWithRedirects
{
  roles : Roles[], 
  redirectTo : string
}

@Injectable()
export class RoleService extends ApiService {
  private readonly roleStore$: Observable<RoleItem>;
  public rolesWithRedirects : RoleWithRedirects[] = [
    {
      roles : [Roles.DASHBOARD_READS],
      redirectTo : '/home'
    },
    {
      roles : [Roles.DOCUMENTS_READ,Roles.DOCUMENTS_WRITE],
      redirectTo : '/home/documents'
    },
    {
      roles : [Roles.ORDERS_READ,Roles.ORDERS_WRITE],
      redirectTo : '/home/orders'
    },
    {
      roles : [Roles.REPORTS_READ,Roles.REPORTS_WRITE],
      redirectTo : '/home/reports'
    },
    {
      roles : [Roles.SUSTAINABILITY_READ],
      redirectTo : '/home/sustainability'
    },
    {
      roles : [Roles.USERS_READ,Roles.USERS_WRITE],
      redirectTo : '/home/accounts'
    },
  ]
  constructor(
    private http: HttpClient,
    private store: Store<{ roles: RoleItem }>
  ) {
    super(http, 'api/identity/auth');
    this.roleStore$ = this.store.select('roles');
  }
  getUserRolesStore(){
    return this.roleStore$.pipe(map((e)=>e.userRoles));
  }
  hasRole(role: Roles) {
    return this.roleStore$.pipe(map((e) => e.userRoles.includes(role)));
  }
  canActivate() {
    return new Promise<boolean>((resolve) => {
      this.setRoles().add(() => {
        resolve(true);
      });
    });
  }
  setRoles() {
    return this.getUserRoles().subscribe(e=>{
      return this.store.dispatch(setRoles({roles : e}));
    });
  }
  getUserRoles(){
    return this.get<Roles[]>('Roles');
  }
}
