export const environment = {
  production: false,
  apiUrl: 'http://localhost:5000/',
  //apiUrl: 'http://localhost:4003/v1.0/invoke/MakorSource/method/',
  wordPressUrl: 'https://linknews.simslifecycle.com/',
  loginDashboardWorkbook: 'LoginDashboard',
  loginDashboardView: 'LoginDashboard',
  tableauServer: 'https://uat.tableau.simslifecycle.com',
  appInsights: {
    instrumentationKey: 'f235315e-26ff-4c87-a131-23ac371c3d08'
  },
  productFruits:{
    key: 'cpAi5TkXeY606cCx'
  }
};
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
