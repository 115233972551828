import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject } from '@angular/core';
@Injectable()
export abstract class ApiService {
    private readonly _url: string;
    private readonly _http: HttpClient;
    private readonly _headers: HttpHeaders;

    constructor(http: HttpClient, @Inject(String) url: string) {
        this._http = http;
        this._url = url;
        this._headers = new HttpHeaders({
            'Accept':'application/json',
            'Content-Type': 'application/json'
        });
    }
    protected get<TResponse>(method: string): Observable<TResponse> {
        return this._http
            .get<TResponse>(`${this._url}/${method}`, { headers: this._headers });
    }
    protected getWithPrams<TResponse>(method:string, params: HttpParams):Observable<TResponse>{
      return this._http
      .get<TResponse>(`${this._url}/${method}`, { headers: this._headers, params: params });
    }
    protected post<TResponse>(
        method: string,
        args: any,
    ): Observable<TResponse> {
        let body = JSON.stringify(args);
        return this._http
            .post<TResponse>(`${this._url}/${method}`, body, { headers: this._headers })
    }

    protected postFile(
      method: string,
      args: any,
  ): Observable<Blob> {
      let body = JSON.stringify(args);
      return this._http
          .post(`${this._url}/${method}`, body, { headers: this._headers, responseType: 'blob' })
  }

    protected delete<TResponse>(
      method: string,
      params: HttpParams): Observable<TResponse> {
        return this._http.delete<TResponse>(`${this._url}/${method}`, { headers: this._headers, params: params })
    }
}
